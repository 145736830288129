import { Component } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-root{',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  toolbar: boolean;

  constructor(router: Router) {
    router.events.subscribe((event)=> {
        if(event instanceof RoutesRecognized)  {
          this.toolbar =  event.state.root.firstChild.data.toolbar;
        }
    });

  }
}
