import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent {

  constructor(private router: Router) { }

  public newsArticles = [
    {title:"SERVAN’s new single “DARK TIMES” is out now!",path:"../../assets/darktimes.jpg"},
    {title:"OUR FIRST RELEASE",path:"../../assets/HARD_TIMES_COVER.jpg"},
    {title:"OUR FIRST SINGLE",path:"../../assets/B.I.G_Steps.jpg"},
  ];

  public videos = ["https://www.youtube.com/embed/tEdVbJIBKE8"];

  public introText = `ARTISTIC REVOLUTION Records is an internationalist and revolutionary record label that fights to bring back real art to the musical landscape, instead of chasing every trend that’s out there. Our struggle is to sign and bring out the real artists who
  live for the music, who stand against injustices. In other words: whose music stands for something. Our motto is: We will never stay silent when it’s needed to be loud. To follow us on our journey, follow us on Social Media and let us close the ranks!`;

  redirectToNews(): void {
    this.router.navigate(['/news']);
  }
}
