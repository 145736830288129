import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit{

  public ngOnInit() {
    // this.httpClient.get('assets/Maze1.txt', {responseType: 'text'})
    // .subscribe(data => console.log(data));

  }

  public newsArticles = [
    {title:"SERVAN’s new single “DARK TIMES” is out now!",
    path:"../../assets/darktimes.jpg",
    text:`Check out the music video on YouTube or Tidal. Available on YouTube (Music), Spotify, Apple Music, amazon music, Deezer, Tidal etc.`},
    {title:"OUR FIRST RELEASE",
    path:"../../assets/HARD_TIMES_COVER.jpg",
    text:`The first release of our new artist ŞERVAN is coming on September 2nd. He’ll give you a listen to his interpretation of the song “Hard Times” which originally was sung by the blues legend John Lee Hooker. We’ll upload the song on streaming platforms
    and as a free track on YouTube, so you can enjoy our first release without any costs. Enjoy!`},
    {title:"OUR FIRST SINGLE",path:"../../assets/B.I.G_Steps.jpg",text:`“Big Steps On A Narrow Way” is the first single of ŞERVAN and it’s coming on September 16th! We’ll announce the release on every social media channel and of course also on our website, too. Be sure to follow us on social media, so you don’t miss
    out on anything. You can find the buttons to our social media accounts on the top of the website on the right side.`},
    {title:"UPCOMING NEWS",path:"../../assets/Logo.png",text:`Currently, we are working on new releases. Stay tuned and enjoy. Peace!`},
  ];

}
