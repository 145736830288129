import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tour-dates',
  templateUrl: './tour-dates.component.html',
  styleUrls: ['./tour-dates.component.css']
})
export class TourDatesComponent {
  public openTour(route: string): void {
    window.open(route, "_blank");
  }
}
