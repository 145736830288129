<div style="min-height: 100vh">
    <div class="video-container">
        <iframe src="https://www.youtube.com/embed/r5QVeaK_TFg" class="video" frameborder="0"
            allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>
    <div class="video-container">
        <iframe src="https://www.youtube.com/embed/rj79GjVq_og" class="video" frameborder="0"
            allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>
    <div class="video-container">
        <iframe src="https://www.youtube.com/embed/tEdVbJIBKE8" class="video" frameborder="0"
            allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>
    <div class="video-container">
        <iframe src="https://www.youtube.com/embed/CGF3jV6T1_Q" class="video" frameborder="0"
            allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>
</div>