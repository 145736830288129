<div class="mediaContainer">
    <div class="container">
        <img style="width: 650px; max-width: 80vw;" [src]="photo" class="image">
        <div style="width: 100%; justify-content: center; display: flex;">
            <div style="display: flex;">
                <div class="actionButton" (click)="previous()">
                    <div>&#10094;</div>
                </div>
                <div class="actionButton zoom" (click)="open=!open">
                    <i style="margin-top: auto;margin-bottom:auto;" class="fa fa-plus"></i>

                </div>
                <div class="actionButton" (click)="next()">
                    <div>&#10095;</div>
                </div>
            </div>
        </div>
    </div>
    <div class="texts" style="margin:auto; scrollbar-color: black; text-align: justify;">
        <div>
            <p style="font-size: 45px; text-align: center; margin-top: 0;">ŞERVAN HEYDERÎ</p>
        </div>
        <div style="overflow-y: scroll; height: 60%; ">
            <p class="artistText" style="font-size: 30px;">{{text}}</p>
        </div>
    </div>
</div>

<div class="modal" *ngIf="open">
    <div style="margin-top: 2.5%; margin-bottom: 2.5%; margin-left: 10%; margin-right: 10%; display: flex; height: fit-content;">
        <a class="closeModal" (click)="open=!open">
            <i class="fa fa-2x fa-times"></i>
        </a>
        <div class="container modalimage" style="height: fit-content; width: fit-content; margin: auto;" #cont>
            <img style="height: auto; width: 100%; margin: auto;" [src]="photo" alt="Avatar" class="image">
            <div class="overlay">
            </div>
        </div>
    </div>
</div>