import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './kontakt/impressum.component';
import { MediaComponent } from './media/media.component';
import { MusicComponent } from './music/music.component';
import { NewsComponent } from './news/news.component';
import { StartComponent } from './start/start.component';
import { TourDatesComponent } from './tour-dates/tour-dates.component';

const routes: Routes = [
  {path: 'start', component: StartComponent, data: {toolbar: true}},
  {path: 'news', component: NewsComponent, data: {toolbar: true} },
  {path: 'tour', component: TourDatesComponent, data: {toolbar: true}},
  {path: '',     component: HomeComponent, data: {toolbar: false}},
  {path: 'music', component: MusicComponent, data: {toolbar: true}},
  {path: 'media', component: MediaComponent, data: {toolbar: true}},
  {path: 'datenschutz', component: DatenschutzComponent, data: {toolbar: true}},
  {path: 'impressum', component: ImpressumComponent, data: {toolbar: true}},
  {path: 'about', component: AboutComponent, data: {toolbar: true}},
  {path: '*',     component: HomeComponent, data: {toolbar: false}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
