<div style="display: flex; margin-bottom: 30px;" class="mobile-logo"> 
    <img src="../../assets/ARTISTIC-REVOLUTION-Records-Logo.png" style="width: 80%; margin-left: auto; margin-right: auto;">
</div>
<!-- <p class="intro-text">{{introText}}</p> -->
<div class="content-separator"></div>
<div class="video-container" *ngFor="let video of videos">
    <iframe src="https://www.youtube.com/embed/r5QVeaK_TFg" class="video" frameborder="0" allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
</div>
<div class="content-separator"></div>
<div class="news-container">
    <div class="container" style="background-color: black; aspect-ratio: 1 / 1; display: flex; margin-left: 30px; margin-right: 30px;" *ngFor="let newsArticle of newsArticles;">
        <div style="background-color: black; aspect-ratio: 1 / 1; display: flex;">
        <img src={{newsArticle.path}} class="image">
        </div>
        <div class="overlay">
            <div class="text">{{newsArticle.title}}</div>
        </div>
        <button (click)="redirectToNews()" class="overlay-button">Read More</button>
    </div>
</div>