import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  public aboutText: String = `ARTISTIC REVOLUTION Records is an internationalist and revolutionary record label founded in 2020 in Cologne, Germany. Our aim as a record label is to bring back the real art to the musical landscape, instead of chasing every trend that’s out there. 
  Music shouldn’t be industrialized; music should be made for the people. Therefore, instead of forcing our artist partners to chase every of those superficial trends out there, our revolutionary role must be to act as a megaphone for the (yet) unheard. 
  To follow us on our journey, follow us on Social Media and let us close the ranks!`;
}
