import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent  {

  currentPhoto = 0;

  open: boolean;

  photo: String;
  text: String = `ŞERVAN is ARR’s first signed artist and he embodies our philosophy as one of the first GUERRILLEROS of the modern music industry. His newly invented music genre called “GUERRILLA Blues” (G-Blues) combines elements of the genres of hip-hop, blues, funk, soul, reggae and jazz. By his own words is “G-Blues musically the dialectical negation of the blues-based genres evolved in the 20th century combined with revolutionary lyrics. With G-Blues we intend to bring hip hop back to its roots, back to the three elements of hip hop – back to its revolutionary elements.”`

  photos: String[] = [
    "../../assets/PORZ BW frontal guitar.jpg",
    "../../assets/brr.jpg",
    "../../assets/GUMMIPLATZ BW down left.jpg",
    "../../assets/GUMMIPLATZ BW Frontal.jpg",
    "../../assets/GUMMIPLATZ colored.jpg",
  ];

  previous() {
    if (this.currentPhoto -1 >= 0) {
      this.currentPhoto = this.currentPhoto - 1;
      this.photo = this.photos[this.currentPhoto];
    } else {
      this.currentPhoto = this.photos.length - 1;
      this.photo = this.photos[this.currentPhoto];
    }

  }

  next() {
    if (this.currentPhoto + 1 < this.photos.length) {
      this.currentPhoto = this.currentPhoto + 1;
      this.photo = this.photos[this.currentPhoto];
    } else {
      this.currentPhoto = 0;
      this.photo = this.photos[this.currentPhoto];
    }

  }

  ngOnInit(): void {
    this.photo = this.photos[0];
  }

  a() {
    this.open = !this.open;
  }

}
