<div class="toolbar-container">
    <div class="toolbar-box">
        <a class="headerButton" href="https://www.instagram.com/artistic_revolution_records/" target="_blank">
            <i class="fa fa-instagram"></i>
        </a>
        <a class="headerButton" href="https://www.youtube.com/channel/UCNw58obC3BAuz_qg9YVMQWQ" target="_blank">
            <i class="fa fa-youtube"></i>
        </a>
        <a class="headerButton" href="https://www.youtube.com/channel/UCoxlecAkRHvK_kYF365q9_w" target="_blank">
            <i class="fa fa-youtube"></i>
        </a>
        <a class="headerButton"
            href="https://www.facebook.com/artisticrevolutionrecords/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_transition=0&show_community_review_changes=0&show_follower_visibility_disclosure=0"
            target="_blank">
            <i class="fa fa-facebook"></i>
        </a>
        <a class="headerButton" href="https://music.amazon.de/artists/B0BG86QYSD/servan" target="_blank">
            <i class="fa fa-amazon"></i>
        </a>
        <a class="headerButton" href="https://open.spotify.com/artist/6f5bThjRdWnQONLbA7Ne8u?si=LmtyfaLuSnCnkJiNJECx1g"
            target="_blank">
            <i class="fa fa-spotify"></i>
        </a>
        <a class="headerButton" href="https://music.apple.com/de/artist/servan/1640156013?l=en" target="_blank">
            <i class="fa fa-apple"></i>
        </a>
    </div>
    <div class="topnav">
        <a class="navlink" routerLink="about" routerLinkActive="b">HOME</a>
        <a class="navlink" routerLink="news" routerLinkActive="b">NEWS</a>
        <a class="navlink" routerLink="tour" routerLinkActive="b">TOUR</a>
        <img class="toolbar-logo" src="../../assets/ARTISTIC-REVOLUTION-Records-Logo.png" (click)="redirectToStart()">
        <a class="navlink" routerLink="music" routerLinkActive="b">MEDIA</a>
        <a class="navlink" routerLink="media" routerLinkActive="b">ARTISTS</a>
        <a class="navlink" href="https://www.artistic-revolution-store.com/" target="_blank">STORE</a>
    </div>
</div>
<div class="hamburger" style="background-color: black; margin-bottom: 40px;">
    <button class="headerButton" style="background-color: black;" (click)="toggleHamburger()">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <div *ngIf="hamburgerOpen" class="menu"   [@inOutAnimation]    >
        <div style="display: flex; width: 100%; background-color: black;">
        <div class="toolbar-box" style="background-color: black; width: fit-content; margin-top:15px !important; margin-left: auto; margin-right: auto; margin-bottom: 20px !important;">
            <a class="headerButton" href="https://www.instagram.com/artistic_revolution_records/" target="_blank">
                <i class="fa fa-instagram"></i>
            </a>
            <a class="headerButton" href="https://www.youtube.com/channel/UCNw58obC3BAuz_qg9YVMQWQ" target="_blank">
                <i class="fa fa-youtube"></i>
            </a>
            <a class="headerButton" href="https://www.youtube.com/channel/UCoxlecAkRHvK_kYF365q9_w" target="_blank">
                <i class="fa fa-youtube"></i>
            </a>
            <a class="headerButton"
                href="https://www.facebook.com/artisticrevolutionrecords/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_transition=0&show_community_review_changes=0&show_follower_visibility_disclosure=0"
                target="_blank">
                <i class="fa fa-facebook"></i>
            </a>
            <a class="headerButton" href="https://music.amazon.de/artists/B0BG86QYSD/servan" target="_blank">
                <i class="fa fa-amazon"></i>
            </a>
            <a class="headerButton" href="https://open.spotify.com/artist/6f5bThjRdWnQONLbA7Ne8u?si=LmtyfaLuSnCnkJiNJECx1g"
                target="_blank">
                <i class="fa fa-spotify"></i>
            </a>
            <a class="headerButton" href="https://music.apple.com/de/artist/servan/1640156013?l=en" target="_blank" style="margin-right: 0px !important;">
                <i class="fa fa-apple"></i>
            </a>
        </div>
    </div>
        <div class="topnav" style="flex-direction: column; display: flex; width: 100% !important;">
            <div class="burger-menu-item" style="border-top: 1px solid grey">
                <a class="burger-link navlink" routerLink="start">HOME</a>
            </div>
            <div class="burger-menu-item">
                <a class="burger-link navlink" routerLink="about">ABOUT</a>
            </div>
            <div
            class="burger-menu-item">
                <a class="burger-link navlink" routerLink="news">NEWS</a>
            </div>
            <div
            class="burger-menu-item">
                <a class="burger-link navlink" routerLink="tour">TOUR</a>
            </div>
            <div
            class="burger-menu-item">
                <a class="burger-link navlink" routerLink="music">MEDIA</a>
            </div>
            <div
            class="burger-menu-item">
                <a class="burger-link navlink" routerLink="media">ARTISTS</a>
            </div>
            <div class="burger-menu-item">
                <a class="burger-link navlink" href="https://www.artistic-revolution-store.com/" target="_blank">STORE</a>
            </div>
        </div>
    </div>
</div>