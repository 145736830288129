import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  constructor() { }

  currentPhoto = 0;

  open: boolean;

  photo: String;
  text: String = "";

  photos: String[] = [
    "../../assets/PORZ BW frontal guitar.jpg",
    "../../assets/brr.jpg",
    "../../assets/GUMMIPLATZ BW down left.jpg",
    "../../assets/GUMMIPLATZ BW Frontal.jpg",
    "../../assets/GUMMIPLATZ colored.jpg",
  ];

  previous() {
    if (this.currentPhoto -1 >= 0) {
      this.currentPhoto = this.currentPhoto - 1;
      this.photo = this.photos[this.currentPhoto];
    } else {
      this.currentPhoto = this.photos.length - 1;
      this.photo = this.photos[this.currentPhoto];
    }

  }

  next() {
    if (this.currentPhoto + 1 < this.photos.length) {
      this.currentPhoto = this.currentPhoto + 1;
      this.photo = this.photos[this.currentPhoto];
    } else {
      this.currentPhoto = 0;
      this.photo = this.photos[this.currentPhoto];
    }

  }

  ngOnInit(): void {
    this.photo = this.photos[0];
  }

  a() {
    this.open = !this.open;
  }
}
