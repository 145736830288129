import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NewsComponent } from './news/news.component';
import { TourDatesComponent } from './tour-dates/tour-dates.component';
import { FooterComponent } from './footer/footer.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ImpressumComponent } from './kontakt/impressum.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MusicComponent } from './music/music.component';
import { MediaComponent } from './media/media.component';
import { ModalModule } from 'angular-custom-modal';
import { StartComponent } from './start/start.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    NewsComponent,
    TourDatesComponent,
    FooterComponent,
    DatenschutzComponent,
    ImpressumComponent,
    HomeComponent,
    AboutComponent,
    MusicComponent,
    MediaComponent,
    StartComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalModule,
  ],
  providers: [ { provide: LocationStrategy, useClass: PathLocationStrategy } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
